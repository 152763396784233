
document.addEventListener("DOMContentLoaded",
  () => {
  
    document.addEventListener("mousemove", parallax);
    console.log(
      'Music from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=music&amp;utm_content=6703">Pixabay</a>'
    )
  }
);

function parallax(event) {
  this.querySelectorAll(".parallax").forEach((shift) => {
    const position = shift.getAttribute("value");
    const x = (window.innerWidth - event.pageX * position) / 90;
    const y = (window.innerHeight - event.pageY * position) / 90;
    shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
  });
}
